import Vue from "vue";
import {
  IBaseConfig,
  ICascadeUpdateData,
  IListDataSourceConfig,
  IUpdateField,
} from "@/ioc/types";
import axios from "axios";
import { getGUID, toQueryString } from "@/cms-services/helpers";
import { checkToken } from "@/cms-services/token";
import { ObjectDataSource } from "../Object/ObjecDatatSource";

export class ListDataSource {
  public metadata!: any;
  public config!: IBaseConfig;
  public items: Array<any> = new Array();
  public total: number = 0;
  public className: string;
  public beforeAdd: any = null;

  get hasItems(): boolean {
    return this.items.length > 0;
  }

  get baseUrl() {
    return `/manage/${this.className}`;
  }

  constructor({ className, config, items, beforeAdd }: IListDataSourceConfig) {
    checkToken();
    this.config = config;
    this.items = items ?? [];
    this.className = className;
    this.beforeAdd = beforeAdd;
  }

  async get() {
    try {
      const url = `${this.baseUrl}/${toQueryString(this.config)}`;
      const { data } = await axios.get(url);
      this.items = data.items;
      this.total = data.total;
      this.config.pageIndex = data.pageIndex;
      this.config.pageSize = data.pageSize;
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getMetaData() {
    try {
      const { data } = await axios.get(`${this.baseUrl}/metadata/`);
      const groups = data.groups.map((g: any) => ({ ...g, id: getGUID() }));
      const properties = data.properties.map((p: any) => ({
        ...p,
        id: getGUID(),
      }));

      Vue.set(this, "metadata", { groups, properties });
    } catch (err) {
      throw err;
    }
  }

  async updateField(id: number, updateData: IUpdateField[]) {
    const model: any = this.items.find((i: any) => i.id === id);
    if (!model) throw new Error("Обьект не найден");
    try {
      const { data } = await axios.patch(`${this.baseUrl}/${id}`, updateData);
      updateData.forEach((ud: any) =>
        Vue.set(model, ud.fieldName, ud.fieldValue),
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  async removeCascade(items: Array<any>) {
    try {
      await axios.delete(`${this.baseUrl}/`, { data: items });
      await this.get();
    } catch (error) {
      throw error;
    }
  }

  async remove(id: number) {
    const index = this.items.findIndex((i: any) => +i.id === +id);

    try {
      await axios.delete(`${this.baseUrl}/${id}/`);
      if (index !== -1){
        this.items.splice(index, 1);
      }
    } catch (error) {
      throw error;
    }
  }

  async add(
    model: any,
    addToEnd: boolean = false,
    index: number | null = null,
  ) {
    if (!model) throw new Error("Нельзя передать null");

    try {
      const { data } = await axios.post(`${this.baseUrl}/`, model);

      if (index == null) {
        addToEnd ? this.items.push(data) : this.items.unshift(data);
        return data;
      }

      if (index == this.items.length - 1 && addToEnd) {
        this.items.push(data);
        await this.changePriority();
        return data;
      }
      const insertIndex=  addToEnd ? index + 1 : index;
      this.items.splice(insertIndex, 0, data);

      await this.changePriority();

      return data;
    } catch (error) {
      throw error;
    }
  }

  public getObjectDataSource(id: number) {
    const model = this.items.find((i: any) => +i.id === +id);
    if (!model) throw new Error("елемент не найден");

    return new ObjectDataSource({
      id,
      model,
      className: this.className,
      config: {},
    });
  }

  async updateFieldCascade(fields: ICascadeUpdateData[]) {
    try {
      const { data } = await axios.patch(`${this.baseUrl}/cascade`, fields);
      return data;
    } catch (err) {
      throw err;
    }
  }

  public getMetaDataByName(name: string) {
    const field = this.metadata.properties.find((i: any) => i.name === name);
    if (!field) throw new Error("Мета данные по имени "+name+" не найдены");
    return field;
  }

  async update(model: any) {
    if (!(this.items?.length > 0)) throw new Error("items не определены");
    if (!model) throw new Error("Нельзя передать null");
    const _model: any = this.items.find((i: any) => i.id === model.id);
    if (!_model) throw new Error("Обьект не найден");
    try {
      const { data } = await axios.put(`${this.baseUrl}/${model.id}`, model);
      Object.assign(_model, data);
    } catch (error) {
      throw error;
    }
  }

  async uploadFile(id: number, formData: FormData) {
    try {
      const { data } = await axios.post(
        `${this.baseUrl}/${id}/file/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        },
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  async changePriority(priorities: any = null, sort: boolean = false) {
    const data =
      priorities ??
      this.items.map((item: any, index: number) => ({
        id: item.id,
        priority: index,
      }));
    try {
      await axios.patch(`${this.baseUrl}/priority/`, data);
      this.items.forEach((item: any, index: number) => {
        item.priority = index;
      });
      if (sort) {
        this.items = this.items.sort(
          (p: any, n: any) => p.priority - n.priority,
        );
      }
    } catch (error) {
      throw error;
    }
  }

  async getItemById(id: any) {
    try {
      const { data } = await axios.get(`${this.baseUrl}/${id}`);
      return data;
    } catch (err) {
      throw err;
    }
  }
}

export class ComponentSeoItemListDataSource extends ListDataSource {
  constructor(config?: IBaseConfig) {
    super({
      className: "ComponentSeoItem",
      config:{...config},
    });
  }

  public async addScope(model:any){
    try {
      const {data} = await axios.post("/manage/componentseoitem/scope/",model);
      
      await this.get();
      
      return data;
    } catch (error) {
      console.log(error);
    }
  }
}

export class PriceCatalogListDataSource extends ListDataSource {
  constructor(config: IBaseConfig) {
    super({
      className: "pricecatalog",
      config,
    });
  }


  async uploadXml(formData: FormData) {
    try {
      const { data } = await axios.post(
        `${this.baseUrl}/upload/xml/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        },
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

}

export class InvitationListDataSource extends ListDataSource {
  constructor(config: IBaseConfig) {
    super({
      className: "Invitation",
      config,
    });
  }

  public async getStatistic(invitationFilter?: string) {
    try {
      let url = `${this.baseUrl}/statistic/${toQueryString(this.config)}`;
      if (invitationFilter) url += `&${invitationFilter}`;
      const { data } = await axios.get(url);
      this.items = data;
      this.total = data.length;
      return data;
    } catch (err) {
      throw err;
    }
  }

  public async downloadStatistic(securityGroupId: number) {
    try {
      const { data } = await axios.get(
        `${this.baseUrl}/statistic/download/xlsx/${toQueryString({
          securityGroupId,
        })}`,
        {
          responseType: "blob",
        },
      );
      const url = window.URL.createObjectURL(data);
      var link = document.createElement("a");
      link.download = "Статистика.xlsx";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return data;
    } catch (err) {
      throw err;
    }
  }
}

export class SecurityGroupUserProfileListDataSource extends ListDataSource{
  constructor(config:IBaseConfig) {
    super({
      className:"SecurityGroupUserProfile",
      config,
    });
  }

  public async addUserListByEmails(model:any){
    try {
      await axios.post(`${this.baseUrl}/list/email`, model);
    } catch (e){
      throw e;
    }
  }
}

export class SecurityGroupAccessListDataSource extends ListDataSource{
  constructor(config:IBaseConfig) {
    super({
      className:"SecurityGroupAccess",
      config,
    });
  }

  public async addUserListByEmails(model:any){
    try {
      await axios.post(`${this.baseUrl}/list/email`, model);
    } catch (e){
      throw e;
    }
  }
}
